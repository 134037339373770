<template>
  <div class="add_hande_home">
    <el-container>
      <el-header>
        <div class="add_hande_header">
          <div>
            <img src="../../assets/bgm/logo.png" alt="" />
          </div>
          <div class="right-menu">
            <div class="flex">
              <i style="color:#4070f9;font-size:18px;cursor: pointer" class="el-icon-setting" @click="personSetting"></i>
              <i style="color:rgb(132,139,255);font-size:18px" class="el-icon-bell"></i>
              <!-- <img src="../../../assets/img/smalllogo.png" alt=""> -->
              <p>{{phone}}</p>
              <p style="cursor: pointer" @click="retreatLogin"><i class="el-icon-switch-button" style="color:red;font-size:18px"></i>退出</p>
              <!-- <el-dropdown style="cursor: pointer">
                <span class="el-dropdown-link">
                  设置<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                
                  <el-dropdown-item @click.native="retreatLogin">退出</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
            </div>
          </div>
        </div>
      </el-header>
      <el-container>
        <el-aside>
          <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo navigation"
            :collapse-transition="false"
            router
          >
            <el-submenu index="1" 
                 v-if="
                  $store.state['user']['PermissionCodeList'].includes(
                    'mwnuSalaryPaymentBehalf'
                  ) ||
                  $store.state['user']['PermissionCodeList'].includes(
                    'menuIssuingPersonnel'
                  ) ||
                  $store.state['user']['PermissionCodeList'].includes(
                    'menuAccountRechargeRecord'
                  )
                ">
              <template slot="title">
                <img 
                 v-if="$route.path == '/SurrogateTable' || $route.path == '/IssuePerson'
                 || $route.path == '/rechargeRecord'" 
                 src="../../assets/bgm/08.png" alt="">
                <img v-else src="../../assets/bgm/Group08.png" alt="">
                <span style="margin-left:10px">代发管理</span>
              </template>
              <el-menu-item-group style="width: 200px">
                <el-menu-item index="/"  v-if=" $store.state['user']['PermissionCodeList'].includes('mwnuSalaryPaymentBehalf' ) ">
                  <span slot="title" style="margin-left: 8px">代发表</span>
                </el-menu-item>
                <el-menu-item index="/IssuePerson"  v-if=" $store.state['user']['PermissionCodeList'].includes('menuIssuingPersonnel' ) ">
                  <span slot="title" style="margin-left: 8px">代发人员</span>
                </el-menu-item>
                <el-menu-item index="/rechargeRecord"  v-if=" $store.state['user']['PermissionCodeList'].includes('menuAccountRechargeRecord' ) ">
                  <span slot="title" style="margin-left: 8px">账户交易记录</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
             <!-- <el-submenu index="2">
              <template slot="title">
                <img 
                 v-if="$route.path == '/SurrogateTable' || $route.path == '/IssuePerson'
                 || $route.path == '/rechargeRecord'" 
                src="../../assets/bgm//Group10-1.png" alt="">
                <img src="../../assets/bgm/Group10.png" alt="">
                <span style="margin-left: 8px">工资条</span>
              </template>
              <el-menu-item-group style="width: 200px">
                <el-menu-item index="/jhs_report" >
                  <span slot="title" style="margin-left: 8px">新建工资条</span>
                </el-menu-item>
                <el-menu-item index="/jhs_businessIntended">
                  <span slot="title" style="margin-left: 8px">发送记录</span>
                </el-menu-item>
                
              </el-menu-item-group>
            </el-submenu> -->
             <el-submenu index="3" 
                 v-if="
                  $store.state['user']['PermissionCodeList'].includes(
                    'menuPayingEntity'
                  ) ||
                  $store.state['user']['PermissionCodeList'].includes(
                    'menuEmployers'
                  )
                ">
              <template slot="title">
                <img 
                 v-if="$route.path == '/paymaster' || $route.path == '/EmploymentEnterprise'" 
                src="../../assets/bgm/09.png" alt="">
                <img v-else src="../../assets/bgm/Group09-1.png" alt="">
                <span style="margin-left: 8px">企业管理</span>
              </template>
              <el-menu-item-group style="width: 200px">
                <el-menu-item index="/paymaster"  v-if=" $store.state['user']['PermissionCodeList'].includes('menuPayingEntity' )">
                  <span slot="title" style="margin-left: 8px">发薪主体</span>
                </el-menu-item>
                <el-menu-item index="/EmploymentEnterprise"  v-if=" $store.state['user']['PermissionCodeList'].includes('menuEmployers' )">
                  <span slot="title" style="margin-left: 8px">用工企业</span>
                </el-menu-item>
                
              </el-menu-item-group>
            </el-submenu>
             <el-submenu index="4"   
                v-if=" 
                  $store.state['user']['PermissionCodeList'].includes(
                    'menuPersonnelAccount'
                  ) ||
                  $store.state['user']['PermissionCodeList'].includes(
                    'menuRolePermission'
                  )
                ">
              <template slot="title">
                 <img
                  v-if="$route.path == '/PersonnelAccount' || $route.path == '/character'" 
                  src="../../assets/bgm/Group11.png" alt="">
                 <img v-else src="../../assets/bgm/11.png" alt="">
                <span style="margin-left: 8px" >设置</span>
              </template>
              <el-menu-item-group style="width: 200px">
                <el-menu-item index="/PersonnelAccount" v-if=" $store.state['user']['PermissionCodeList'].includes('menuPersonnelAccount' )">
                  <span slot="title" style="margin-left: 8px">人员账号</span>
                </el-menu-item>
                <el-menu-item index="/character" v-if=" $store.state['user']['PermissionCodeList'].includes('menuRolePermission' )">
                  <span slot="title" style="margin-left: 8px">角色权限</span>
                </el-menu-item>
                
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
          <transition name="fade-transform" mode="out-in">
            <router-view></router-view>
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { loginOut,getToken } from '@/api/login.js'
export default {
  name:'home',
  data () {
    return {
      phone:'',
    }
  },
  mounted() {
    this.getIphone ()
    this.$store.dispatch('user/user_getPermissionCode')
  
  },
  methods:{
    //推出
     retreatLogin () {
      this.$confirm('确定要退出系统吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
            const res = await loginOut()
              if ( res.success ) {
                this.$router.push({ name: "login" });
                 this.$message({
                    type: 'success',
                    message: '退出成功!'
                  });
              } else {
                this.$message.error(res.message)
              }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出'
          });          
        });
    
    },
     //获取手机号码
    async getIphone () {
        const res = await getToken()
          if ( res.success ) {
              this.phone = res.data.username
              localStorage.setItem('phone',this.phone)
              localStorage.setItem('employee',res.data.employee)
          } else {
              this.$message.error(res.message)
          }
      },
   personSetting () {
      this.$router.push({ name: "personCenter" });
   }
  },
 
}
</script>

<style lang="less" scoped>
@import "../../style/home.less"; 
</style>