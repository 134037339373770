var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add_hande_home"},[_c('el-container',[_c('el-header',[_c('div',{staticClass:"add_hande_header"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/bgm/logo.png"),"alt":""}})]),_c('div',{staticClass:"right-menu"},[_c('div',{staticClass:"flex"},[_c('i',{staticClass:"el-icon-setting",staticStyle:{"color":"#4070f9","font-size":"18px","cursor":"pointer"},on:{"click":_vm.personSetting}}),_c('i',{staticClass:"el-icon-bell",staticStyle:{"color":"rgb(132,139,255)","font-size":"18px"}}),_c('p',[_vm._v(_vm._s(_vm.phone))]),_c('p',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.retreatLogin}},[_c('i',{staticClass:"el-icon-switch-button",staticStyle:{"color":"red","font-size":"18px"}}),_vm._v("退出")])])])])]),_c('el-container',[_c('el-aside',[_c('el-menu',{staticClass:"el-menu-vertical-demo navigation",attrs:{"default-active":_vm.$route.path,"collapse-transition":false,"router":""}},[(
                _vm.$store.state['user']['PermissionCodeList'].includes(
                  'mwnuSalaryPaymentBehalf'
                ) ||
                _vm.$store.state['user']['PermissionCodeList'].includes(
                  'menuIssuingPersonnel'
                ) ||
                _vm.$store.state['user']['PermissionCodeList'].includes(
                  'menuAccountRechargeRecord'
                )
              )?_c('el-submenu',{attrs:{"index":"1"}},[_c('template',{slot:"title"},[(_vm.$route.path == '/SurrogateTable' || _vm.$route.path == '/IssuePerson'
               || _vm.$route.path == '/rechargeRecord')?_c('img',{attrs:{"src":require("../../assets/bgm/08.png"),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/bgm/Group08.png"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("代发管理")])]),_c('el-menu-item-group',{staticStyle:{"width":"200px"}},[( _vm.$store.state['user']['PermissionCodeList'].includes('mwnuSalaryPaymentBehalf' ) )?_c('el-menu-item',{attrs:{"index":"/"}},[_c('span',{staticStyle:{"margin-left":"8px"},attrs:{"slot":"title"},slot:"title"},[_vm._v("代发表")])]):_vm._e(),( _vm.$store.state['user']['PermissionCodeList'].includes('menuIssuingPersonnel' ) )?_c('el-menu-item',{attrs:{"index":"/IssuePerson"}},[_c('span',{staticStyle:{"margin-left":"8px"},attrs:{"slot":"title"},slot:"title"},[_vm._v("代发人员")])]):_vm._e(),( _vm.$store.state['user']['PermissionCodeList'].includes('menuAccountRechargeRecord' ) )?_c('el-menu-item',{attrs:{"index":"/rechargeRecord"}},[_c('span',{staticStyle:{"margin-left":"8px"},attrs:{"slot":"title"},slot:"title"},[_vm._v("账户交易记录")])]):_vm._e()],1)],2):_vm._e(),(
                _vm.$store.state['user']['PermissionCodeList'].includes(
                  'menuPayingEntity'
                ) ||
                _vm.$store.state['user']['PermissionCodeList'].includes(
                  'menuEmployers'
                )
              )?_c('el-submenu',{attrs:{"index":"3"}},[_c('template',{slot:"title"},[(_vm.$route.path == '/paymaster' || _vm.$route.path == '/EmploymentEnterprise')?_c('img',{attrs:{"src":require("../../assets/bgm/09.png"),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/bgm/Group09-1.png"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"8px"}},[_vm._v("企业管理")])]),_c('el-menu-item-group',{staticStyle:{"width":"200px"}},[( _vm.$store.state['user']['PermissionCodeList'].includes('menuPayingEntity' ))?_c('el-menu-item',{attrs:{"index":"/paymaster"}},[_c('span',{staticStyle:{"margin-left":"8px"},attrs:{"slot":"title"},slot:"title"},[_vm._v("发薪主体")])]):_vm._e(),( _vm.$store.state['user']['PermissionCodeList'].includes('menuEmployers' ))?_c('el-menu-item',{attrs:{"index":"/EmploymentEnterprise"}},[_c('span',{staticStyle:{"margin-left":"8px"},attrs:{"slot":"title"},slot:"title"},[_vm._v("用工企业")])]):_vm._e()],1)],2):_vm._e(),( 
                _vm.$store.state['user']['PermissionCodeList'].includes(
                  'menuPersonnelAccount'
                ) ||
                _vm.$store.state['user']['PermissionCodeList'].includes(
                  'menuRolePermission'
                )
              )?_c('el-submenu',{attrs:{"index":"4"}},[_c('template',{slot:"title"},[(_vm.$route.path == '/PersonnelAccount' || _vm.$route.path == '/character')?_c('img',{attrs:{"src":require("../../assets/bgm/Group11.png"),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/bgm/11.png"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"8px"}},[_vm._v("设置")])]),_c('el-menu-item-group',{staticStyle:{"width":"200px"}},[( _vm.$store.state['user']['PermissionCodeList'].includes('menuPersonnelAccount' ))?_c('el-menu-item',{attrs:{"index":"/PersonnelAccount"}},[_c('span',{staticStyle:{"margin-left":"8px"},attrs:{"slot":"title"},slot:"title"},[_vm._v("人员账号")])]):_vm._e(),( _vm.$store.state['user']['PermissionCodeList'].includes('menuRolePermission' ))?_c('el-menu-item',{attrs:{"index":"/character"}},[_c('span',{staticStyle:{"margin-left":"8px"},attrs:{"slot":"title"},slot:"title"},[_vm._v("角色权限")])]):_vm._e()],1)],2):_vm._e()],1)],1),_c('el-main',[_c('transition',{attrs:{"name":"fade-transform","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }